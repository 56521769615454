import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import rehypeRaw from 'rehype-raw'
import Image from "../../components/image"

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      title
      content
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, height: 700)
          }
        }
      }
      slug
      link
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiArticle
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  }

  return (
    <Layout active="blog" page={article.slug}>
      <Seo seo={seo} />
      <section> 
        <div className="featured-slider">
          <div className="slider-content">
            <div className="slider-image">
              <Image
                image={article.image.localFile.childImageSharp.gatsbyImageData}
                alt={`Afbeelding van ${article.title}`}
                sizes={'100vw'}
              />
            </div>
          </div>
        </div>
        <div className="slider-text" style={{ marginTop: 60, maxWidth: 800 }}>
          <h1>{article.title}</h1>
        </div>
      </section>

      <section style={{ paddingTop: 0, paddingBottom: 80 }}>
        <div className="container">
          <div className="row">
            <div className="custom-col-10 text-md">
              <ReactMarkdown rehypePlugins={[rehypeRaw]} children={article.content} />
            </div>
          </div>
          <div className="row" style={{ marginTop: 40 }}>
            <div className="custom-col-10">
              <p>Bron: <a target="_blank" rel="noreferrer" href={article.link}>{article.link}</a></p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    )
}

export default Article